import {useToast} from 'vue-toast-notification';

export default {
    methods: {
        showToast(res, type = "error",dismissible = false) {
            const duration = dismissible ? 999999 : 5000;
            if (typeof res === "object" && !Array.isArray(res) && res !== null) {
              if(res?.response?.status === 422){
                const errorMessages = [... new Set(res.response.data.data.errors.map(el=>el.msg))]
                errorMessages.forEach(error=>{
                    this.$toast.error(error ,{
                    duration : 5000
                    })
                })
                return;
              }
              this.$toast[type](res.message + "; " + res.response?.data?.message, {
                duration: 5000,
              });
            }else {
              if(dismissible) {
                return this.$toast[type](res,{
                  duration
                })
              }else{
                this.$toast[type](res, {
                  duration
                });
              }
            }
          },
    }
}