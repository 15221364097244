import store from '../store'

const TokenKey = 'Admin-Token'
const TokenExpiration = 'Token-Expiration'
const RefreshToken = 'Refresh-Token'

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

// Token Expiration
export function getTokenExpiration() {
  return sessionStorage.getItem(TokenExpiration)
}
export function setTokenExpiration(tokenExp) {
  return sessionStorage.setItem(TokenExpiration, tokenExp)
}
export function removeTokenExpiration() {
  return sessionStorage.removeItem(TokenExpiration)
}
// Refresh Token
export function getRefreshToken() {
  return sessionStorage.getItem(RefreshToken)
}
export function setRefreshToken(refreshToken) {
  return sessionStorage.setItem(RefreshToken, refreshToken)
}
export function removeRefreshToken() {
  return sessionStorage.removeItem(RefreshToken)
}