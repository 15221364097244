import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  login_loading: true,
  uploading_overlay: false
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1, { sameSite: 'strict' })
    } else {
      Cookies.set('sidebarStatus', 0, { sameSite: 'strict' })
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0, { sameSite: 'strict' })
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  LOGIN_LOADING: (state, login_loading) => {
    state.login_loading = login_loading
  },
  UPLOADING_OVERLAY: (state, uploading_overlay) => {
    state.uploading_overlay = uploading_overlay
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  openLoginLoading({ commit }) {
    commit('LOGIN_LOADING', false)
  },
  closeLoginLoading({ commit }) {
    commit('LOGIN_LOADING', true)
  },
  toggleUploadingOverlay({ commit }, status) {
    commit('UPLOADING_OVERLAY', status)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
