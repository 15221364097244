<template>
    <b-container fluid id="app" class="app-wrapper-login" :style="{backgroundImage: '-webkit-linear-gradient(160deg, #F5A847 '+ gradient.toString()  +'%, #4f2684 18%)'}">

        <b-row >
            <b-col :lg="first">
                <div style="margin-top:8%;">
                    <img v-show="bannerMargin =='none'" src="../assets/images/bee.png" style="border-style:solid; border-color:white; height:11%; width:11%; border-radius:50%;">
                    <img @load="onImg2Load" src="../assets/images/logo5.jpg" :style="{float: bannerMargin}" style="width:53%;">
                    <img @load="onImg3Load" v-show="bannerMargin !=='none'" src="../assets/images/bee.png" :style="{float: bannerMargin}" style="border-style:solid; border-color:white; height:11%; width:11%; border-radius:50%;">
                </div>
               
            </b-col>
            <b-col v-show="showLogo" :lg="second">
                
                <div :style="{marginTop: logoTopMargin.toString()+'%', height: logoHeight+'%', width: logoWidth+'%', marginRight: logoRightMargin+'%'}" style="display:block; margin-right: auto; margin-left: auto;">
                    <img @load="onImg1Load" src="../assets/images/onlinebilling-2.png" style="width:100%; border-radius:50%;">
                </div>
              
            </b-col>

            <b-col :lg="third" align-v="center" :style="{marginTop: top.toString()+'%'}">
                    
                        <app-main/>

            </b-col>
        </b-row>
  
        <b-container fluid class="login-animation" :hidden="login_loading">
            <b-spinner variant="light" class="login-spinner"></b-spinner>
        </b-container>
        <vue-splash
        
            :show="!showMain"
            :logo="logo"
            color="#F5A847"
            :size="150"
            :fixed="true"
        />
    </b-container>

</template>

<script>
    
import Vue from 'vue';
import VueSplash from 'vue-splash';
import logo from '../assets/images/bee_splash.png'
import { AppMain } from './components'
import { mapGetters } from 'vuex'

Vue.use(VueSplash);


export default {
    name: "LayoutLogin",
    props: ["variable"],
     components: {
        AppMain
    },
    computed: {
        ...mapGetters([
            'login_loading',
        ]),
        logo() {
            return logo;
        },
        showMain(){
            return this.isLoaded1 && this.isLoaded2 && this.isLoaded3;    
        },
    },
    data() {
        return {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            isLoaded1: false,
            isLoaded2: false,
            isLoaded3: false,
            top: 0,
            gradient: 0,
            origHeight: 0,
            logoTopMargin: 0,
            logoHeight: 0,
            logoWidth: 0,
            logoRightMargin: 0,

            first: '4',
            second: '3',
            third:  '5',

            showLogo: true,
            bannerMargin: '',
        }
    },
    created(){

        if(this.windowWidth <= 990){
            this.showLogo = false
            this.bannerMargin = 'none'
        } else {
            this.showLogo = true
            this.bannerMargin = 'right'
        }

        var height = this.windowHeight / 100
        if(height <= 20){
            this.top = height
            this.logoTopMargin = 62.5 - height
            this.logoHeight = height * 2 + 45
            this.logoWidth = height * 2 + 45
        } else {
            this.top = 20
            this.logoTopMargin = 40
            this.logoHeight = 80
            this.logoWidth = 80
        }

        var width = this.windowWidth / 300
        if(width <= 16){
            this.gradient = 58.5 - width
            this.logoRightMargin = 38.5 - (width * 6)
        } else {
            this.gradient = 40
            this.logoRightMargin = -65
        }
      
        
    },
    watch: {
        windowHeight(newHeight, oldHeight) {
            var height = newHeight / 100
            if(height <= 20){
                this.top = height
                this.logoTopMargin = 62.5 - height
                this.logoHeight = height * 2 + 45
                this.logoWidth = height * 2 + 45
            } else {
                this.top = 20
                this.logoTopMargin = 40
                this.logoHeight = 80
                this.logoWidth = 80
            }
        },
        windowWidth(newWidth) {
            if(newWidth <= 990){
                this.showLogo = false
                this.bannerMargin = 'none'
            } else {
                this.showLogo = true
                this.bannerMargin = 'right'
            }

            var width = newWidth / 300
            if(width <= 16){
                this.gradient = 58.5 - width
                this.logoRightMargin = 38.5 - (width * 6)
            } else {
                this.gradient = 40
                this.logoRightMargin = -65
            } 
        }
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight
            this.windowWidth = window.innerWidth
        },
        onImg1Load () {
            setTimeout(() => this.isLoaded1 = true, 3000);
        },
        onImg2Load () {
            this.isLoaded2 = true
        },
        onImg3Load () {
            this.isLoaded3 = true
        },

    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    mounted: function(){
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    }

}
</script>