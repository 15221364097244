<template>
    <b-modal no-close-on-backdrop no-close-on-esc :ok-title="'Send'" id="FeedbackModal" size="md" title="Feedback" @ok="save" @cancel="cancel()">
        <div slot="modal-header-close">
            <b-icon-x></b-icon-x>
        </div>
        
        <b-form-textarea
        id="textarea"
        v-model="feedback_text"
        placeholder="Enter feedback..."
        rows="3"
        max-rows="6"
        maxlength="300"
        :state="feedbackState"
        @input="feedbackValidate()"
        ></b-form-textarea>

        <!-- <label class="switch float-right" v-b-tooltip.hover title="Send Anonymously" style="position:relative; top:0.5rem">
            <input type="checkbox" v-model="anonymous" value="true" unchecked-value="false">
            <span class="slider round"></span>
        </label>    -->

        <label class="switch float-right" title="Send Anonymously" style="position:relative; top:0.5rem">
            <input type="checkbox" v-model="anonymous" value="true" unchecked-value="false">
            <span class="slider round">
            <span class="on">YES</span><span class="off">NO</span>
            </span>
        </label> 

        <label  class="float-right" style="position:relative; top:0.6rem; font-size:14px; padding-right:0.5rem">Send Anonymously: </label>
    </b-modal>
</template>

<script>

import { createFeedback } from '@/api/billing-portal';

export default {
    props:{
        creator:String,
        // anonymous:Boolean
    },
    data: function() {
        return{
            feedback_text: '',
            feedback_creator: '',
            anonymous: false,
            feedbackState: null
        }
    },
    methods:{
        feedbackValidate(){
            if(this.feedback_text.trim().length > 0){
                this.feedbackState = null
            } else {
                this.feedbackState = false
            }
        },
        save(bvModalEvt){
            bvModalEvt.preventDefault()

            if(this.feedback_text.trim().length > 0) {
                if (this.anonymous == true){
                    this.feedback_creator = ''
                } else {
                    this.feedback_creator = this.creator
                }
                createFeedback({
                    creator: this.feedback_creator, 
                    feedback_message: this.feedback_text, 
                }).then((response) => {  
                    this.$toast.success('Thank you for the feedback!',{
                        duration : 5000
                    })
                    this.$nextTick(() => {
                        this.$bvModal.hide('FeedbackModal')
                    })  
                })
                .catch((error) => {
                    var msg = ''
                    if(error.response !== undefined)
                        if(error.response.status == 422){
                            msg = error.response.data.data.errors[0].msg
                        } else {
                            msg = error.response.data.message
                        }
                    else
                        msg = 'Network error!'
                    
                    this.$nextTick(() => {
                        this.$bvModal.hide('FeedbackModal')
                    })  
                    this.$toast.error('Oops! Something went wrong. ' + msg,{
                        duration : 5000
                    })
                });
            } else {
                this.feedbackValidate()
            }
        },
        cancel(){
            // alert('cancel')
        }
        
    },
    mounted(){
        // alert(this.anonymous)
    }

}
</script>