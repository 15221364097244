<template>
    <div>
        <b-navbar type="dark" variant="csb" fixed="absolute" sticky style="box-shadow: 0px 1px 4px 2px grey">
            <slot></slot>

            <b-navbar-brand class="ml-3" href="#">
                <img src="../../assets/images/logo5.jpg" style="width:10rem">
            </b-navbar-brand>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown class="ml-2" right no-caret>
                        <template #button-content>
                            <div v-if="user_info.sso_profile_pic" style="overflow: hidden;color:white; border-radius:50%;"
                                    v-bind:style="{boxShadow: boxshadowProfile, backgroundColor: backgroundProfile, cursor: 'pointer'}"
                                    @mouseover="boxshadowProfile=shadowButton, backgroundProfile=backgroundButton"
                                    @mouseout="boxshadowProfile='none', backgroundProfile='transparent'"
                                    @click="showTooltipUser=false"
                                    id="tooltipuser">
                                        <img v-if="user_info.sso_profile_pic" :src="user_info.sso_profile_pic" alt="" style="width:30px">
                                </div>
                                <b-icon-person-circle v-else v-bind:style="{boxShadow: boxshadowProfile, backgroundColor: backgroundProfile, cursor: 'pointer'}"
                                            style="color:white; border-radius:50%;"
                                            @mouseover="boxshadowProfile=shadowButton, backgroundProfile=backgroundButton"
                                            @mouseout="boxshadowProfile='none', backgroundProfile='transparent'"
                                            @click="showTooltipUser=false"
                                            id="tooltipuser"></b-icon-person-circle>
                        </template>
                                <b-card  bg-variant="white"  style="width:300px; border: 0px;" >
                                    <a>{{fullname}} </a> <a style="font-size:15px; opacity:70%">{{username}}  </a> 
                                    <br>
                                    <hr>
                                    <a>Roles: </a>
                                    <br>
                                    <a v-for="num in roles" :key="num.index" style="font-size:15px; opacity:70%">{{num}} <br></a>
                                    <!-- <br> -->
                                    <!-- <a>Branch: </a>
                                    <br>
                                    <a style="font-size:15px; opacity:70%">{{user_department}} - {{user_branch}}</a>
                                    <br> -->
                                    <hr style="margin-bottom:0px">
                                    <!-- <b-row  @mousedown="write_feedback = true; refresh_key++" @click="openFeedback()" variant="outline-secondary" class="button-hover">
                                        <b-container>
                                            Send Feedback
                                        </b-container>
                                    </b-row> -->
                                    
                                    <hr style="margin-top:0px; margin-bottom:0px">
                                    <b-row  @mousedown="write_feedback = true; refresh_key++" @click="logout()" variant="outline-secondary" class="button-hover">
                                        <b-container>
                                            Sign Out
                                        </b-container>
                                    </b-row>
                                </b-card>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
        </b-navbar>
        <feedback-modal :creator="fullname" :anonymous="anonymous" v-if="write_feedback == true" :key="refresh_key"></feedback-modal>
    </div>
</template>

<style scoped>
.button-hover{
    padding-top:0.5rem;
    padding-bottom:0.5rem;
}
.button-hover:hover{
    background-color: #F5A847;
    cursor:pointer;
}
</style>

<script>
import { mapGetters } from 'vuex'

// import jwt_decode from "jwt-decode";

import FeedbackModal from './FeedbackModal.vue'

export default {
    components : {
        FeedbackModal
    },
    computed:{
        ...mapGetters([
            'user_info',
            'roles'
        ]),
    },
    data: function() {
        return {

            username: "",
            fullname:"",
            user_department: '',
            user_branch: '',
            anonymous: false,
            write_feedback: false, //rendering feedback modal
            refresh_key: 1,

    
            //themes
            shadowButton: "0px 0px 1px 11px #823fd9",
            backgroundButton: "#823fd9",

            boxshadowProfile: 'none',
            backgroundProfile: 'transparent',

            showTooltipUser: false,

        
            data:[]

        }
    },
    created(){

    },
  methods: {
        async logout() {
        await this.$store.dispatch('user/logout')
        // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        this.$router.push(`/login/index`)
        },
      
        openFeedback(){
            this.$bvModal.show('FeedbackModal')
        }
  },

  mounted(){
    this.username = this.user_info.email
    var mname = ''
    if (this.user_info.mname !== '' && this.user_info.mname !== null){
        mname = this.user_info.mname.slice(0,1) + '. '
    }

    this.fullname = (this.user_info.fname + ' ' + mname + this.user_info.lname)
    this.user_department = this.user_info.department
    this.user_branch = this.user_info.branch_name
    localStorage.fullname = this.fullname

  }
}
</script>
