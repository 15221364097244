import request from '@/utils/request'
import loginRequest from '@/utils/loginRequest'

export function login(data) {
  return loginRequest({
    url: 'auth/getTokenV3',
    method: 'post',
    data
  })
}

export function loginGoogle(data) {
  return loginRequest({
    url: 'auth/getTokenSSOv2',
    method: 'post',
    data
  })
}

export function refreshAccessToken(data) {
  return loginRequest({
    url: 'auth/refresh',
    method: 'post',
    data
  })
}

export function getInfo(userId) {
  return request({
    url: 'users/' + userId,
    method: 'get',
  })
}

export function changePassword(data, userId) {
  return request({
    url: 'users/change/pw/' + userId,
    method: 'put',
    data
  })
}

export function getIp() {
  return request({
    url: 'https://api.ipify.org/?format=json',
    method: 'get',
  })
}

export function getTnc(id) {
  return request({
    url: 'policy/guidelines/BEYONDDEPEDLGU/' + id,
    method: 'get',
  })
}

export function saveTnc(data) {
  return loginRequest({
    url: 'policy/guidelines',
    method: 'post',
    data
  })
}