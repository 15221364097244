<template>
    <div class="app-wrapper">
        <navbar>

            <span style="cursor:pointer; font-size:20px">    
            <b-icon-list class="ml-2" v-bind:style="{boxShadow: boxshadowNav,backgroundColor: backgroundNav}"
                                     style="color:white; border-radius:50%"
                                     @click="burgerMenuSidebar(), background1='black', opacity1='0.5', display1='block', left1='0px', showTooltipNav=false"
                                     @mouseover="boxshadowNav=shadowButton, backgroundNav=backgroundButton"
                                     @mouseout="boxshadowNav='none', backgroundNav='transparent'"
                                     id="tooltipnav"></b-icon-list></span>
            
        </navbar>
        <!-- <h6>Width: {{ window.width }} px  }}</h6> -->
        <div style="overflow: auto;">
            <!-- <div :id="slider"> -->
            <div class="sidenav" :class="hoverSize">
                <!-- Billing -->
                
                <div v-if="showRpsu" id="currentBillingTooltip" class="hoverable" style="margin-top:1rem">
                    <b-link :to="billing_ci" style="font-size:18px; text-decoration: none;">
                    <img src="../assets/images/bill.svg" style="width:40px; margin-right:1rem"><span v-if="!label" >CPS</span></b-link>
                </div>
                <b-tooltip v-if="label" target="currentBillingTooltip" triggers="hover" placement="right">
                    CPS
                </b-tooltip>

                <div v-if="showReports" id="reportsTooltip" v-b-tooltip.hover.right="reportsTooltip" class="hoverable" style="margin-top:1rem">
                    <b-link :to="reports" style="font-size:18px; text-decoration: none;">
                    <img src="../assets/images/reports.svg" style="width:40px; margin-right:1rem"><span v-if="!label" >Reports</span></b-link>
                </div>
            

                <div v-if="showDpsu" id="depedBillingTooltip" class="hoverable" style="margin-top:1rem">
                    <b-link :to="deped_billing_ci" style="font-size:18px; text-decoration: none;">
                    <img src="../assets/images/bill.svg" style="width:40px; margin-right:1rem"><span v-if="!label" >Payroll</span></b-link>
                </div>
                <b-tooltip v-if="label" target="depedBillingTooltip" triggers="hover" placement="right">
                    Payroll
                </b-tooltip>

                <!-- AFP tabs -->

                <div v-if="showAfpBilling" id="billingGeneration" class="hoverable" style="margin-top:1rem" v-b-tooltip.hover.right="'Billing Generation'">
                    <b-link :to="afp_billing" style="font-size:18px; text-decoration: none;">
                    <img src="../assets/images/billing_afp.png" style="width:40px; margin-right:1rem"><span v-if="!label" >Billing Generation</span></b-link>
                </div>
              
                <div v-if="showAfpRemittance" id="afpRemittanceTooltip" class="hoverable" style="margin-top:1rem">
                    <b-link :to="afp_remittance" style="font-size:18px; text-decoration: none;">
                    <img src="../assets/images/remittance_afp.png" style="width:40px; margin-right:1rem"><span v-if="!label" >Remittance</span></b-link>
                </div>
                <b-tooltip v-if="label" target="afpRemittanceTooltip" triggers="hover" placement="right">
                    Remittance
                </b-tooltip>

                <div v-if="showAfpReports" id="afpReportsTooltip" v-b-tooltip.hover.right="reportsTooltip" class="hoverable" style="margin-top:1rem">
                    <b-link :to="afp_reports" style="font-size:18px; text-decoration: none;">
                    <img src="../assets/images/reports_afp.png" style="width:40px; margin-right:1rem"><span v-if="!label" >Reports</span></b-link>
                </div>
           
            <!-- SETTINGS MAINTENANCE  -->
            </div>
            <!-- </div> -->
            <app-main :class="contentStatusSidebar"/>
        </div>

         <b-modal  id="inactivityModal"
              ref="modal"
              size="lg"
              no-close-on-backdrop no-close-on-esc
              title="Automatic Logout For Your Protection"
              @ok="timeout()"
              @cancel="logout()"
              ok-title="Keep me logged in"
              cancel-title="Logout"
              ok-variant="primary"
              cancel-variant="danger"
              scrollable>
            <p class="my-4">Due to inactivity, your current work session is about to expire. For your security, Access Management sessions automatically close after 15 minutes of inactivity</p>
            <p>Your current session will expire in {{(session_timeout)}} seconds</p>
        </b-modal>

    </div>
</template>

<script>
import { AppMain, Navbar } from './components'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
  },
    data: function() {
        return {
            window: {
                width: 0,
                height: 0
            },
            //themes
            shadowButton: "0px 0px 1px 11px #823fd9",
            backgroundButton: "#823fd9",
            //tooltips
            showTooltipNav: false,
             //tooltips onhover button highlight
            boxshadowNav: 'none',
            backgroundNav: 'transparent',
            label: true,
            statusSidebar: true,

            
            showBilling: false,
            showRctl: false,
            showReports: false,
            showDpsu: false,
            showReturn: false,
            showAfpBilling:false,
            showAfpReports: false,
            showAfpRemittance: false,
            
            signout: '',

            session_timeout : 60,
            countdown_toggle : null,

            countdown_start: false,
        

        }
    },
    watch:{
        isIdle(newVal,oldVal){
            if(newVal == true){
                this.$bvModal.show('inactivityModal')
                if(!this.countdown_start) this.countdownTimer()
            }
        }
    },
  computed: {
      
    isIdle() {
        return this.$store.state.idleVue.isIdle;
    },

    contentStatusSidebar() {
      return this.statusSidebar ? 'contenthide' : 'contentshow';
    },
    slider() {
      return this.statusSidebar ? 'sidebarCollapsed' : 'sidebarShow';
    },
    hoverSize() {
        return this.statusSidebar ? 'hoversize-small' : 'hoversize-large';
    },
    ...mapGetters([
            'permission_routes',
            'roles'
        ]),
        billing_ci(){
            var temp = this.permission_routes.filter(i => {
                return i.path === '/currentBilling'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/currentBilling'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'currentBilling'}}
            }
        },

        reports(){
            var temp = this.permission_routes.filter(i => {
                return i.path === '/reports'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/reports'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'reports'}}
            }
        },

        deped_billing_ci(){
            var temp = this.permission_routes.filter(i => {
                return i.path === '/depedBilling'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/depedBilling'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'depedBilling'}}
            }
        },

        field_ci(){
            var temp = this.permission_routes.filter(i => {
                return i.path === '/return'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/return'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'return'}}
            }
        },
        // AFP paths
        afp_billing(){
             var temp = this.permission_routes.filter(i => {
                return i.path === '/afp/billing'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/afp/billing'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'afp_billing'}}
            }
        },
        
        afp_remittance(){
             var temp = this.permission_routes.filter(i => {
                return i.path === '/afp/remittance'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/afp/remittance'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'afpRemittance'}}
            }
        },
        afp_reports(){
            var temp = this.permission_routes.filter(i => {
                return i.path === '/afp/reports'
            })

            if(Object.keys(temp[0].children).length !== 0) {
                return '/afp/reports'
            } else {
                return {name: 'ForbiddenPage', params: {id: 'afpReports'}}
            }
        },

        reportsTooltip(){
            if(this.statusSidebar) return 'Reports'
            else return ''
        },
    },
    methods: {
        ...mapActions({
            'toggleSideBar':'app/toggleSideBar',
            'closeSideBar':'app/closeSideBar'
         }),
        async timeout(){
            this.session_timeout = 60
            await clearTimeout(this.countdown_toggle)
            this.countdown_start = false
        },
        countdownTimer(){
            this.countdown_start = true
            if(this.session_timeout > 0) {
                this.countdown_toggle = setTimeout(() => {
                    this.session_timeout = this.session_timeout - 1
                    this.countdownTimer()
                }, 1000)
            }else{
                this.logout()
            }
        },
        async logout() {
            this.timeout()
            await this.$store.dispatch('user/logout')
            this.$router.push('/login')
        },
        handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;

                if(this.window.width <= 800){
                    this.statusSidebar = true                                     
                    this.label = true
                    
                }
            },
        handleHover(hovered) {
            if(hovered == true){
                this.hoverable = 'hovered-true'
            } else {
                this.hoverable = 'hovered-false'
            }

        },
        burgerMenuSidebar() {

            if(this.window.width >= 800){
                this.statusSidebar = !this.statusSidebar;
                if(this.statusSidebar == false){                                // pagbukas ng sidebar
                    setTimeout(() => this.label = this.statusSidebar, 100);
                    this.toggleSideBar()
                } else {                                                        // pagsara ng sidebar
                    this.label = this.statusSidebar
                    this.closeSideBar(true)
                }
            }
        },
  },
  created(){
      // this.burgerMenuSidebar()
      window.addEventListener('resize', this.handleResize);
        this.handleResize();

        var temp = this.permission_routes.filter(i => {
            return i.path === '/billing' || 
            i.path === '/return' ||  
            i.path === '/currentBilling' ||
            i.path === '/depedBilling'||
            i.path === '/reports' ||
            i.path === '/afp/billing'||
            i.path === '/afp/remittance' ||
            i.path === '/afp/reports'
        })

        temp.forEach(i => {
            if(i.children.length !== 0){
                switch(i.path){
                    case '/billing':
                        this.showBilling = true
                        break;
                    case '/currentBilling':
                        this.showRpsu = true
                        break;
                    case '/depedBilling':
                        this.showDpsu = true
                        break;
                    case '/return':
                        this.showReturn = true
                        break;
                    case '/reports':
                        this.showReports = true
                        break;
                    case '/afp/billing':
                        this.showAfpBilling = true
                        break;
                    case '/afp/reports':
                        this.showAfpReports = true
                        break;
                    case '/afp/remittance':
                        this.showAfpRemittance = true
                        break;
                    default:
                        // code block
                    }
            }
        })
     
  },
  destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>

.hoverable:hover{
    background-color: #F5A847;  
}

/* adjust width of menu item on hover depending on sidebar status if closed or open */
.hoversize-large{
    width: 260px;
}

.hoversize-small{
    width: 240px;
    padding-right: 170px;
}

/* content show / hide */

.contentshow {
    overflow: auto;
    transition: all 0.3s;
    position: relative;
    margin-left: 260px;
    border-left: 1px solid #eee;
 
}

.contenthide {
    overflow: auto;
    transition: all 0.3s;
    position: relative;
    margin-left: 70px;
    border-left: 1px solid #eee;

}

/*side navbar*/
.sidenav {
    position: absolute;
    float: left;
    overflow: scroll;
    scrollbar-width: none;
  }

  @media only screen and (max-width: 1026px) {
    .sidenav {
           
        }
    }

  .sidenav a {
    padding: 8px 8px 8px 14px;
    text-decoration: none;
    font-size: 25px;
    color: Black;
    display: block;
    transition: 0.3s;
  }

/* #sidebarShow {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    width: 290px;
   
    padding: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-right: 1px solid #eee;
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - 56px);
    transition: all 0.3s;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
}

#sidebarCollapsed {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    width: 70px;
  
    padding: 5px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    border-right: 1px solid #eee;
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - 56px);
    transition: all 0.3s;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
} */

html {
    overflow: scroll;
    overflow-x: hidden;
}

/*PANG HIDE NG SCROLLBAR*/
::-webkit-scrollbar {
    /*Remove scrollbar space*/
    width: 0px;  
    /*  Optional: just make scrollbar invisible */
    background: transparent; 
}


/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

}
</style>
