const getters = {
  token: state => state.user.token,
  user_info: state => state.user.user_info,
  roles: state => state.user.roles,
  user_id: state => state.user.user_id,
  permission_routes: state => state.permission.routes,
  added_routes: state => state.permission.addRoutes,
  is_pw_temp: state => state.user.is_pw_temp,
  login_loading: state => state.app.login_loading,
  modal_loading: state => state.talktomaria.modal_loading,
  expired_token_alert: state => state.talktomaria.expired_token_alert,
  uploading_overlay: state => state.app.uploading_overlay,
}
export default getters
