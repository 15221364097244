import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import axiosRetry from 'axios-retry';
import router from '../router'
import Vue from 'vue';

import appConfig from '@/utils/appConfig'



// create an axios instance
const service = axios.create({
  baseURL: appConfig().baseApi, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 0 // request timeout
})

axiosRetry(service, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
// request interceptor
service.interceptors.request.use(
    config => {
      // do something before request is sent

      if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers['authtoken'] = getToken()
      }

      return config
    },

  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response

    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200 && res.status !== 201) {
      console.log('token expired')

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if(error.response.status == 400 && store.getters.expired_token_alert == false){
        const vm = new Vue()
        vm.$bvModal.msgBoxOk('Your session has expired. Please relogin to continue.', {
          title: 'Session Expired',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          store.dispatch('user/logout')
          store.dispatch('talktomaria/expiredTokenAlert', false)
          router.push('/')
        })
        .catch(err => {
          console.log('error in token expired alert', err)
        })
        store.dispatch('talktomaria/expiredTokenAlert', true)
        return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default service
