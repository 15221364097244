

export default function () {
    const  app = {
        apiDomain:'',
        environment:'',
        version:'',
        host:'',
        clientId:'',
        appId: process.env.VUE_APP_ID
      }
      if(location.host == process.env.VUE_APP_HOST_PROD){     //PROD
        app.apiDomain = process.env.VUE_APP_API_DOMAIN_PROD
        app.environment = 'PROD'
        app.version = process.env.VUE_APP_VERSION_PROD
        app.host = process.env.VUE_APP_HOST_PROD
        app.clientId = process.env.VUE_APP_CLIENT_ID_PROD
      }
      else if(location.host == process.env.VUE_APP_HOST_UAT){  //UAT
        app.apiDomain = process.env.VUE_APP_API_DOMAIN_UAT
        app.environment = 'UAT'
        app.version = process.env.VUE_APP_VERSION_UAT
        app.host = process.env.VUE_APP_HOST_UAT
        app.clientId = process.env.VUE_APP_CLIENT_ID_DEV //same client id to dev
      }
      else if(location.host == process.env.VUE_APP_HOST_DEV || location.host.includes('localhost') ) {  //DEV
        app.apiDomain = process.env.VUE_APP_API_DOMAIN_DEV
        app.environment = 'DEV'
        app.version = process.env.VUE_APP_VERSION_DEV
        app.host = process.env.VUE_APP_HOST_DEV
        app.clientId = process.env.VUE_APP_CLIENT_ID_DEV
      }
      app['baseApi'] = app.apiDomain + '/v1/newlos/'
      return app
}