import Vue from 'vue'

Vue.filter('humanizedNumber', function(table, field1, field2, field3) {
    let tableCopy = table;
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }
    tableCopy.forEach(row => {
        if (row[field1] !== null) {
            row[field1] = row[field1].toLocaleString(undefined, options);
        }
        if (field3 && row[field3] !== null) {
            row[field3] = row[field3].toLocaleString(undefined, options);
        }
        if (field2 && row[field2] !== null) {
            row[field2] = row[field2].toLocaleString(undefined, options);
        }
    });
    return tableCopy;
})