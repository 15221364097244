import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import LayoutLogin from '@/layoutLogin'


export const constantRoutes = [{
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [{
            path: '/redirect/:path(.*)',
            component: () =>
                import ('@/views/redirect/index')
        }]
    },

    {
        path: '/login',
        hidden: true,
        component: LayoutLogin,
        redirect: '/login/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/login/index'),
            name: 'Login',
            meta: { title: 'Login' }
        }]
    },

    {
        path: '/change-password',
        hidden: true,
        component: LayoutLogin,
        redirect: '/change-password/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/change-password/index'),
            name: 'ChangePassword',
            meta: { title: 'Change Password' }
        }]
    },
    {
        path: '/auth-redirect',
        component: () =>
            import ('@/views/login/auth-redirect'),
        hidden: true
    },
    {
        path: '/401',
        component: () =>
            import ('@/views/error-page/401'),
        hidden: true
    },
    {
        path: '/',
        //Route Guard
        beforeEnter: (to, from, next) => {
            const roles = store.getters.roles

            //LGU
            if (roles.includes('Payroll Admin' || 'Payroll Officer'))
                next({ name: 'depedBilling' })
            else if (roles.includes('CPS Personnel'))
                next({ name: 'currentBilling' })
            else if (roles.includes('Guest' || 'Collections'))
                next({ name: 'reports' })

            //AFP
            else if (roles.includes('CPS TL') || roles.includes('CPS TM'))
                next({ name: 'billing' })
            else if (roles.includes('RCTL-AFP') || roles.includes('RCTL AFP'))
                next({ name: 'afpReports' })
            else
                next({ name: 'ForbiddenPage' })
        }
    },

    {
        path: '/forbidden_page',
        component: Layout,
        redirect: '/forbidden_page/index',
        children: [{
            path: 'index/:id',
            component: () =>
                import ('@/views/forbidden_page/index'),
            name: 'ForbiddenPage',
            meta: { title: 'ForbiddenPage' }
        }]
    },

]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    // LGU routes
    {
        path: '/currentBilling',
        component: Layout,
        redirect: '/currentBilling/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/Current_Billing/index'),
            name: 'currentBilling',
            meta: { title: 'currentBilling', roles: ['CPS Personnel'] }
        }]
    },

    {
        path: '/depedBilling',
        component: Layout,
        redirect: '/depedBilling/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/Deped_Billing/index'),
            name: 'depedBilling',
            meta: { title: 'depedBilling', roles: ['Payroll Admin', 'Payroll Officer'] }
        }]
    },

    {
        path: '/reports',
        component: Layout,
        redirect: '/reports/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/Current_Billing/Reports.vue'),
            name: 'reports',
            meta: { title: 'reports', roles: ['CPS Personnel', 'RCTL', 'Guest', 'Collections', ] }
        }]
    },

    // AFP routes
    {
        path: '/afp/billing',
        component: Layout,
        redirect: '/afp/billing/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/AFP_Billing/index.vue'),
            name: 'billing',
            meta: { title: 'reports', roles: ['CPS TM', 'CPS TL'] }
        }]
    },

    {
        path: '/afp/reports',
        component: Layout,
        redirect: '/afp/reports/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/AFP_Reports/index.vue'),
            name: 'afpReports',
            meta: { title: 'afpReports', roles: ['RCTL AFP', 'RCTL-AFP', 'CPS TM', 'CPS TL'] }
        }]
    },

    {
        path: '/afp/remittance',
        component: Layout,
        redirect: '/afp/remittance/index',
        children: [{
            path: 'index',
            component: () =>
                import ('@/views/AFP_Remittance/index.vue'),
            name: 'afpRemittance',
            meta: { title: 'afpRemittance', roles: ['RCTL AFP', 'RCTL-AFP', 'CPS TM', 'CPS TL'] }
        }]
    },

    // 404 page must be placed at the end !!!
    {
        path: '*',
        component: Layout,
        redirect: '/error-page/404',
        children: [{
            path: '404',
            component: () =>
                import ('@/views/error-page/404'),
            name: '404',
            meta: { title: '404' }
        }]
    },
]


const createRouter = () => new Router({
    mode: 'history', // require service support
    base: '/',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router