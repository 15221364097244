import request from '@/utils/request'

import download from '@/utils/download'

import attachments from '@/utils/attachments'

export function createFeedback(data) {
    return request({
        url: 'feedback',
        method: 'post',
        data
    })
}

export function getRPSU(type, userId) {
    return request({
        url: 'rpsu/getRPSU/' + type + '/' + userId,
        method: 'get',
    })
}

// export function getBillingFiles(billingId) {
//   return request({
//     url: 'rpsuBillingFiles/byBillingId/'+ billingId,
//     method: 'get',
//   })
// }

export function getBillingPeriod(rpsuId) {
    return request({
        url: 'rpsuBilling/byRPSU/' + rpsuId,
        method: 'get',
    })
}

export function addBillingPeriod(data) {
    return request({
        url: 'rpsuBilling',
        method: 'post',
        data
    })
}

export function addBillingFiles(data) {
    return request({
        url: 'rpsuBillingFiles',
        method: 'post',
        data
    })
}

export function addBillingFileHistory(data) {
    return request({
        url: 'rpsuBillingFiles/history',
        method: 'post',
        data
    })
}

export function getBillingFileHistory(billingFileId) {
    return request({
        url: 'rpsuBillingFiles/history/' + billingFileId,
        method: 'get',
    })
}

export function getBillingFileById(id) {
    return attachments({
        url: 'rpsuBillingFiles/getFile/' + id,
        method: 'get',
        responseType: 'blob'
    })
}

export function getOnlineBilling() {
    return request({
        url: 'rpsu',
        method: 'get',
    })
}

export function getBillingFiles(id, date) {
    return request({
        url: 'rpsuBillingFiles/getFilesByBillingId/' + id + '/' + date,
        method: 'get',
    })
}

export function getBillingTable(id, flag) {
    return download({
        url: 'rpsuBillingFiles/getFile/' + id + flag,
        method: 'get',

    })
}

export function getBillingZip(id, date, type) {
    return download({
        url: 'rpsuBillingFiles/getFile/' + id + '/' + date + '/' + type,
        method: 'get',
        responseType: 'blob',
    })
}

export function getDownloadFiles(id) {
    return request({
        url: 'billing/billingFIle/getFIle/' + id,
        method: 'get',
        responseType: 'arraybuffer'
    })
}



export function getBillingHistory(id) {
    return request({
        url: 'rpsuBillingFiles/history/' + id,
        method: 'get',

    })
}

export function getBillingFilesValidate(data) {
    return request({
        url: 'rpsuBillingFiles/validate',
        method: 'post',
        data
    })
}

export function saveTableData(data) {
    return request({
        url: '/rpsuBillingFiles/saveBilling',
        method: 'post',
        data
    })
}

export function getSavedTable(id, date) {
    return request({
        url: 'rpsuBillingFiles/getBilling/' + id + '/' + date,
        method: 'get',

    })
}

export function getLGU(date, type, id) {
    return request({
        url: 'billing/LGU/list?limit=1000&billing_period=' + date + '&product_type=' + type, //+ '&cps=' + id,
        method: 'get',

    })
}

export function addBilling(id, date, type, branch, group, psd) {
    return request({
        url: 'billing/generate/' + id + '?billing_period=' + date + '&product_type=' + type + '&branch_code=' + branch + '&group_code=' + group + '&payment_start_date=' + psd,
        method: 'get',

    })
}

export function getBillingLGUSpecific(id, date) {
    return request({
        url: 'billing/LGU/' + id + '?billing_period=' + date,
        method: 'get',

    })
}

export function sendBilling(id, data) {
    return request({
        url: 'billing/LGU/' + id,
        method: 'post',
        data
    })
}

export function editBilling(data) {
    return request({
        url: 'billing/add',
        method: 'post',
        data
    })
}

export function searchBilling(params) {
    return request({
        url: '/billing/search?' + params,
        method: 'get',

    })
}

export function getPayrollBilling(id, date) {
    return request({
        url: 'billing/LGU/list?billing_period=' + date + '&payroll_admin=' + id,
        method: 'get',

    })
}

export function editAmountPaidBilling(data, id) {
    return request({
        url: 'billing/LGU/' + id,
        method: 'put',
        data
    })
}


export function uploadBilling(data) {
    return request({
        url: 'billing/upload',
        method: 'post',
        data
    })
}


export function getRemittanceFile(id, date) {
    return download({
        url: 'billing/download/' + id + '?product_type=LGU&billing_period=' + date,
        method: 'get',
        responseType: 'blob',
    })
}

export function sendRemittance(id, data) {
    return request({
        url: 'billing/remittance/LGU/' + id,
        method: 'post',
        data
    })
}



export function getOtherfiles(id, date) {
    return request({
        url: 'billing/getFiles/LGU/' + id + '?billing_period=' + date,
        method: 'get'
    })
}


export function downloadOtherFiles(id) {
    return download({
        url: 'billing/download/byFile/' + id,
        method: 'get',
        responseType: 'blob',
    })
}

export function downloadOtherFilesZip(id, date) {
    return download({
        url: 'billing/download/zip/' + id + '?product_type=LGU&billing_period=' + date,
        method: 'get',
        responseType: 'blob',
    })
}

export function getActivity(id, date, type) {
    return request({
        url: 'billing/history/' + id + '?product_type=LGU&billing_period=' + date + '&type=' + type,
        method: 'get'
    })
}

export function getConsolidated(date, type) {
    return request({
        url: 'billing/consolidate?billing_period=' + date + '&product_type=' + type,
        method: 'get'
    })
}

export function downloadConsolidated(date, prod_type, type) {
    return download({
        url: 'billing/consolidate?billing_period=' + date + '&product_type=' + prod_type + '&type=' + type,
        method: 'get',
        responseType: 'blob',
    })
}

export function getSummary(date, type) {
    return request({
        url: 'billing/download/summary/report?billing_period=' + date + '&product_type=' + type,
        method: 'get'
    })
}

export function downloadSummary(date, prod_type, type) {
    return download({
        url: '/billing/download/summary/report?billing_period=' + date + '&product_type=' + prod_type + '&type=' + type,
        method: 'get',
        responseType: 'blob',
    })
}

export function sendPosting(id, data) {
    return request({
        url: 'billing/update/posting/' + id,
        method: 'put',
        data
    })
}

export function listOfValues(lov) {
    return request({
        url: 'lookup/values/byname/' + lov,
        method: 'get'
    })
}

export function getBranchByType(type) {
    return request({
        url: 'sol/type?company=' + type,
        method: 'get',
    })
}

export function getExclusion() {
    return request({
        url: 'billing/list/exclusion',
        method: 'get'
    })
}

export function addExclusion(data) {
    return request({
        url: 'billing/add/exclusion',
        method: 'post',
        data
    })
}

export function updateExclusion(id, data) {
    return request({
        url: 'billing/update/exclusion/' + id,
        method: 'put',
        data
    })
}

export function getBranchCodes() {
    return request({
        url: "sol/type?company=City%20Savings%20Bank",
        method: 'get'
    })
}

export function getDFOBs(billing_period, type) {
    return request({
        url: `billing/dfob?billing_period=${billing_period}&product_type=${type}`,
        method: 'get'
    })
}

export function getDFOBRecord(billing_period, type, id) {
    return request({
        url: `billing/dfob?billing_period=${billing_period}&product_type=${type}&file_sys_id=${id}`,
        method: 'get'
    })
}

export function deleteDFOB(billing_period, type, id, data) {
    return request({
        url: `billing/dfob/delete/${id}?billing_period=${billing_period}&product_type=${type}`,
        method: 'put',
        data
    })
}

export function getValidation(billing_period, type) {
    return request({
        url: `billing/validation/report?billing_period=${billing_period}&product_type=${type}`,
        method: 'get',
    })
}

export function getBillingEfficiency(billing_period, type, group_code, level) {
    return request({
        url: `billing/report/efficiency/${level}?product_type=${type}&billing_period=${billing_period}${level==='access_level'?'&group_code='+group_code:''}`,
        method: 'get'
    })
}

export function getConsolidatedV2(type, data) {
    return request({
        url: `billing/generation/consolidated/${type}`,
        method: 'post',
        data
    })
}
export function deleteProofOfPayment(id) {
    return request({
        url: 'billing/file/attachment/' + id,
        method: 'put',
    })
}

export function returnBilling(id, data) {
    return request({
        url: 'billing/record/update/return/' + id,
        method: 'put',
        data
    })
}

export function saveTableBulk(data) {
    return request({
        url: 'billing/record/update/all',
        method: 'put',
        data
    })
}

export function editTableEntry(data, id) {
    return request({
        url: 'billing/update/cps/' + id,
        method: 'put',
        data
    })
}

export function uploadExclusion(data) {
    return request({
        url: 'billing/exclusion/upload',
        method: 'post',
        data
    })
}

export function exportAfpConsolidated(type, group_code, year, month, loan_type, send_email) {
    return request({
        url: `billing/afp/report/export?group_code=${group_code}&month=${month}&year=${year}&type=${type}${loan_type?'&loan_type='+loan_type:''}${send_email?'&email='+send_email:''}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function getBillingTableAFP(group, year, month) {
    return request({
        url: 'billing/afp/report/list?group_code=' + group + '&year=' + year + '&month=' + month,
        method: 'get'
    })
}

export function getBillingStatusAFP(group, year, month) {
    return request({
        url: 'billing/afp/status/record?year=' + year + '&month=' + month + '&group_code=' + group,
        method: 'get'
    })
}

export function saveBillingTableAFP(data) {
    return request({
        url: 'billing/afp/report/new',
        method: 'post',
        data
    })
}

export function editBillingTableAFP(data) {
    return request({
        url: 'billing/afp/report/update',
        method: 'put',
        data
    })
}

export function getActivityAFP(group, year, month, type) {
    return request({
        url: 'billing/history/' + group + '?product_type=AFP&type=' + type + '&billing_period=' + year + '-' + month,
        method: 'get'
    })
}

export function deleteBillingTableAFP(data) {
    return request({
        url: 'billing/afp/report/remove',
        method: 'put',
        data
    })
}

export function approveBillingAFP(data) {
    return request({
        url: 'billing/afp/status/update',
        method: 'put',
        data
    })
}


export function getAfpConsolidated(group_code, year, month) {
    return request({
        url: `billing/afp/consolidated/report?group_code=${group_code}&year=${year}&month=${month}`,
        method: 'get',
    })
}

export function notifyBillingAFP(data) {
    return request({
        url: 'billing/afp/status/notif',
        method: 'post',
        data
    })
}

export function returnBillingAFP(data) {
    return request({
        url: 'billing/afp/report/return',
        method: 'put',
        data
    })
}

export function regenerateBillingAFP(data) {
    return request({
        url: 'billing/afp/report/automated/list',
        method: 'post',
        data
    })
}

export function getRemittanceList(date, group_code) {
    return request({
        url: `remittance?remittance_date=${date}&group_code=${group_code}`,
        method: 'get',
    })
}

export function uploadRemittance(data) {
    return request({
        url: 'remittance/upload',
        method: 'post',
        data
    })
}

export function getRemittanceFiles(date, group_code) {
    return request({
        url: `remittance/upload?remittance_date=${date}&group_code=${group_code}`,
        method: 'get'
    })
}

export function setRemittanceStatus(data) {
    return request({
        url: 'remittance',
        method: 'post',
        data
    })
}

export function getRemittanceActivity(date, group_code) {
    return request({
        url: `remittance/activity/history?remittance_date=${date}&group_code=${group_code}`,
        method: 'get'
    })
}

export function addByCustId(cust_id) {
    return request({
        url: 'core/inquiry/loans?core_cust_id=' + cust_id,
        method: 'get',
    })
}

export function getRemittanceFileById(id) {
    return request({
        url: 'remittance/download/' + id,
        method: 'get',
        responseType: 'arraybuffer'
    })
}

export function editRemittance(id, data) {
    return request({
        url: 'remittance/' + id,
        method: 'put',
        data
    })
}

export function mapRemittance(action, data) {
    return request({
        url: 'remittance/' + action,
        method: 'post',
        data
    })
}

export function getT24UploadStatus(group_code, date_from, date_to) {
    return request({
        url: `remittance/upload/ftp/status?group_code=${group_code}&date_from=${date_from}&date_to=${date_to}&type=t24`,
        method: 'get'
    })
}

export function downloadRemittanceZip(date, group_code) {
    return download({
        url: `remittance/zip?remittance_date=${date}&group_code=${group_code}`,
        method: 'get',
        responseType: 'blob',
    })
}

export function getDepedExtract(date) {
    return request({
        url: `remittance/extract?date=${date}`,
        method: 'get',
    })
}

export function getExtractLogs() {
    return request({
        url: `remittance/extract/logs`,
        method: 'get',
    })
}

export function getAfpBillingEff(type,group_code,remittance_date) {
    return request({
        url: `remittance/efficiency/${type}?group_code=${group_code}&remittance_date=${remittance_date}`,
        method: 'get',
    })
}

export function getServiceCharge(group_code,remittance_date){
    return request({
        url: `remittance/report/service/charge?group_code=${group_code}&remittance_date=${remittance_date}`,
        method: 'get'
    })
}

export function getAfpAttachments(application_id,group_code,billing_date){
    return request({
        url: `billing/afp/attachment/${application_id}?group_code=${group_code}&billing_period=${billing_date}`,
        method: 'get'
    })
}

export function downloadAfpZip(application_id,group_code,billing_date){
    return request({
        url: `billing/afp/download/zip/${application_id}?group_code=${group_code}&billing_period=${billing_date}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function downloadAfpFile(sys_id,group_code,billing_date){
    return request({
        url: `billing/afp/download/attachment/${sys_id}?group_code=${group_code}&billing_period=${billing_date}`,
        method: 'get',
        responseType: 'arraybuffer'
    })
}

