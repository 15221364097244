<template>
    <!-- <b-overlay :show="uploading_overlay" rounded="sm" z-index="5000" opacity="0.3"> -->
      <router-view></router-view>
      <!-- <template #overlay>
        <div class="text-center">
          <b-icon icon="arrow-clockwise" font-scale="3" animation="spin"></b-icon>
          <p id="cancel-label">Uploading...</p>
        </div>
      </template>
    </b-overlay> -->
    
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
    name: "index",
    // data(){
    //     return {
    //       show: true
    //     }
    // },
    // computed: {
    //      ...mapGetters([
    //         'uploading_overlay',
    //     ]),
    // },
    // methods: {
     
    // }
}
</script>
<style lang="scss">

@import './assets/_custom.scss';
// Bootstrap and its default variables
@import '../node_modules/bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '../node_modules/bootstrap-vue/src/index.scss';

</style>

<style>

@import './assets/app.css';
@import './assets/toast.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
