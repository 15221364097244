import Vue from 'vue';
// import worker from 'worker-loader'

import App from './App'
import store from './store'
import router from './router'

import './permission' // permission control
import './utils/globalFilter'
import toastMixin from './mixins/toastMixin'

import axios from 'axios'
import VueToast from 'vue-toast-notification';
import Logger from './utils/logger';

import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';


import GoogleSignInButton from 'vue-google-signin-button-directive'


Vue.prototype.axios = axios
Vue.use(Logger);
Vue.use(VueToast);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.mixin(toastMixin);

import IdleVue from "idle-vue";

const eventsHub = new Vue({
    el: '#app',
    router: router,
    store: store,
    GoogleSignInButton,

    render: h => h(App),
})

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 900000, // 15 minutes
    startAtIdle: false
});

Vue.config.productionTip = false